import { defineStore } from "pinia";
import { api } from "@/services/http";
import { computed, ref } from "vue";
import { ElNotification } from "element-plus";

const URL = "/role";

export const useRoleStore = defineStore("roleStore", () => {
  const roles = ref([]);

  const getRoles = async ({ limit = 1000, offset = 0, ...filters }) => {
    try {
      const { data, count } = await api.get(URL, {
        params: { limit, offset, ...filters },
      });

      roles.value = data;

      return { data, count };
    } catch (e) {
      throw Error(e);
    }
  };

  const updateRole = async (formData, editIndex) => {
    try {
      await api.put(URL, formData);

      const getMessage = () => {
        const state = editIndex === -1 ? "added" : "updated";

        return `${formData.name} role ${state}`;
      };

      ElNotification.success({
        message: getMessage(),
      });
    } catch (e) {
      throw Error(e);
    }
  };

  const getRolesSlug = computed(() => {
    return roles.value.map(({ slug }) => slug);
  });

  return {
    getRoles,
    updateRole,
    getRolesSlug,
  };
});
