<script setup>
import AppIcon from "@/components/app/AppIcon";
import { useAuthStore, useProfileStore } from "@/stores";

const authStore = useAuthStore();
const profileStore = useProfileStore();
</script>

<template>
  <el-menu
    mode="horizontal"
    text-color="#A5A8B3"
    active-text-color="#1F2641"
    :ellipsis="false"
    :default-active="$route.path"
    router
  >
    <el-menu-item index="/dashboard">
      <AppIcon name="dashboard" width="18" height="18" />
      <span class="ml-1">Dashboard</span>
    </el-menu-item>
    <el-menu-item index="/notifications">
      <AppIcon name="notification" width="18" height="18" />
      <span class="ml-1">Notifications</span>
    </el-menu-item>
    <el-menu-item index="/applications">
      <AppIcon name="application" width="18" height="18" />
      <span class="ml-1">Applications</span>
    </el-menu-item>
    <el-sub-menu index="/reports">
      <template #title>
        <AppIcon name="report" width="18" height="18" />
        <span class="ml-1">Reports</span>
      </template>
      <el-menu-item index="/reports/report-of-sent-pushes">
        Report of sent pushes
      </el-menu-item>
      <el-menu-item index="/reports/conversion-report">
        Conversion report
      </el-menu-item>
      <el-menu-item index="/reports/application-install">
        Application install
      </el-menu-item>
    </el-sub-menu>
    <el-sub-menu index="/settings">
      <template #title>
        <AppIcon name="setting" width="18" height="18" />
        <span class="ml-1">Settings</span>
      </template>
      <el-menu-item index="/settings/admin-users">Admin users</el-menu-item>
      <el-menu-item index="/settings/roles">Roles</el-menu-item>
      <el-menu-item index="/settings/route-permissions">
        Route permissions
      </el-menu-item>
      <el-menu-item index="/settings/tracking-events">
        Tracking events
      </el-menu-item>
      <el-menu-item index="/settings/proofs">Proofs</el-menu-item>
    </el-sub-menu>
  </el-menu>
  <div class="Profile">
    <div>
      <div class="Profile-Name" v-text="profileStore.profile.name" />
      <div class="Profile-Email" v-text="profileStore.profile.email" />
    </div>
    <el-tooltip effect="light" content="Logout" placement="bottom-end">
      <el-button
        icon="SwitchButton"
        circle
        :loading="authStore.isLoading"
        @click="authStore.logout()"
      />
    </el-tooltip>
  </div>
</template>
